<template>
  <v-container fluid grey lighten-3 pa-10>
    <v-row class="justify-center">
      <v-col>
        <v-card class="mx-auto" max-width="450">
          <v-card-title>เพิ่ม สินค้า</v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="form" @submit.prevent="submit">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      :items="categories"
                      item-text="name"
                      item-value="id"
                      :rules="[v => !!v || 'กรุณาเลือก']"
                      label="กรุณาเลือก หมวดหมู่"
                      @change="catSelected"
                    >
                      <template v-slot:item="{item}">
                           <v-avatar size="24" class="mr-5">
                               <v-img v-if="item.image" :src="item.image | imageUrl"></v-img> 
                            </v-avatar> <span>{{item.name}}</span>
                      </template>
                    
                    </v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model.trim="product.name"
                      :rules="nameRules"
                      :counter="120"
                      label="ชื่อ สินค้า"
                      id="name"
                      maxlength="120"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model.trim="product.subname"
                      :counter="120"
                      maxlength="120"
                      label="subname"
                      id="subname"
                    ></v-text-field>
                  </v-col>

                    <v-col cols="12">
                    <v-text-field
                      v-model.trim="product.label_search"
                      :counter="120"
                      maxlength="120"
                      label="ชื่อย่อ สำหรับจับคู่สินค้าอัตโนมัติ ตอนลิสรายการ"
                      id="label_search"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="product.cost"
                      type="number"
                      label="ราคาทุน 0.00 บาท"
                      id="cost"
                      suffix="บาท"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="product.no"
                      type="number"
                      label="No ลำดับ"
                      id="no"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-file-input
                      label="File input"
                      accept="image/*"
                      prepend-icon="mdi-camera"
                      type="file"
                      @change="onFileSelected"
                    ></v-file-input>
                    <div v-if="imageUrl">
                      <v-img
                        v-if="imageUrl"
                        :src="imageUrl"
                        max-height="150"
                        max-width="150"
                        class="mt-2"
                      ></v-img>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-switch
                      v-model="product.active"
                      label="Active"
                      color="success"
                      input-value="1"
                      hide-details
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-divider class="mt-5 mb-5"></v-divider>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn color="gray" class="mr-5" @click="reset">ยกเลิก</v-btn>
                  <v-btn :disabled="!valid" color="success" type="submit"
                    >บันทึก</v-btn
                  >
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/api";

export default {
  name: "ProductCreate",
  data() {
    return {
      categories: [],
      product: {
        name: "",
        subname: "",
        image: "",
        no: 0,
        active: 0,
        cost: 0,
        category_id: 0,
        label_search: ""
      },
      nameRules: [
        v => !!v || "Name is required",
        v =>
          (typeof v != "undefined" && v.length <= 50) ||
          "Name must be less than 50 characters"
      ],
      imageUrl: null,
      valid: true
    };
  },
  methods: {
    catSelected(id) {
        this.product.category_id = id;
    },
    async submit() {
      //alert(JSON.stringify(this.product))

      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("name", this.product.name);
        formData.append("subname", this.product.subname);
        formData.append("category_id", this.product.category_id);
        formData.append("no", this.product.no);
        formData.append("cost", this.product.cost);
        formData.append("active", this.product.active ? 1 : 0);
        formData.append("label_search", this.product.label_search);

        if (this.product.image) {
            formData.append("image", this.product.image);
        }

        await api.addProduct(formData);
        this.$router.push("/product");
      }
    },
    onFileSelected(event) {
      const reader = new FileReader();
      if (typeof event == "undefined") {
        this.imageUrl = "";
      }

      reader.onload = event => {
        this.imageUrl = event.target.result; // for preview
      };

      if (event) {
        reader.readAsDataURL(event); //for upload
        this.product.image = event;
      }
    },
    cancel() {
      this.$router.push("/Product");
    },
    reset() {
      this.imageUrl = "";
      this.product.image = "";
      this.$refs.form.reset();
    }
  },
  async mounted() {
    let result = await api.getCategoryActive();
    this.categories = result.data;
  }
};
</script>

<style></style>
